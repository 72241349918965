






















import { Vue, Component, Mixins, Emit, Prop, PropSync } from 'vue-property-decorator';
import { InputSetups } from "@/mixins/input-setups";
import MediaUploader from "piramis-base-components/src/components/MediaUploader/MediaUploader.vue";
import { MediaFileData } from "piramis-base-components/src/components/File/types";
import Api from "../../includes/logic/Api";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import RadioInput from 'piramis-base-components/src/components/Pi/fields/RadioInput/RadioInput.vue'
import { SelectOption } from "piramis-base-components/src/logic/types";

const enum ImportTypeEnum {
  'All' = 'All',
  'Config' = 'Config',
  'Triggers' = 'Triggers'
}

@Component({
  components: {
    MediaUploader,
    RadioInput,
  }
})
export default class ImportConfigFromChat extends Mixins(InputSetups) {
  @PropSync('importConfigLoading', { type: Boolean, required: true, default: false }) isLoading!: boolean

  @Emit()
  done() {
    return null
  }

  importType: ImportTypeEnum | null = null

  someFile: File | null = null

  get uploadParams() {
    const params: Record<string, any> = {
      chat_id: this.$store.getters.chatId.toString()
    }

    switch (this.importType) {
      case ImportTypeEnum.Config:
        params.dont_import_triggers = true;
        break;
      case ImportTypeEnum.Triggers:
        params.dont_import_config = true
        break
    }

    return params
  }

  get chatImportTypes(): Array<SelectOption> {
    return [
      {
        label: this.$t(`import_config_option_${ ImportTypeEnum.All.toLowerCase() }`).toString(),
        value: ImportTypeEnum.All
      },
      {
        label: this.$t(`import_config_option_${ ImportTypeEnum.Config.toLowerCase() }`).toString(),
        value: ImportTypeEnum.Config
      },
      {
        label: this.$t(`import_config_option_${ ImportTypeEnum.Triggers.toLowerCase() }`).toString(),
        value: ImportTypeEnum.Triggers
      },
    ]
  }

  doRequest(formData: FormData) {
    this.isLoading = true

    Api.sendRequest('tg', 'importchatconfig', formData)
      .then(this.successImport)
      .then(_ => successNotification())
      .catch(errorNotification)
      .finally(() =>{
        this.isLoading = false
        this.someFile = null
        this.done()
      })
  }

  upload() {
    const fd = new FormData()

    Object.entries(this.uploadParams).forEach(([ k, v ]) => {
      fd.append(k, v)
    })

    if (this.someFile) {
      fd.append('config', this.someFile)
    }

    this.doRequest(fd)
  }

  handleFileUpload(file: MediaFileData) {
    this.someFile = file.blobFile

    this.upload()
  }

  successImport() {
    this.$store.dispatch("requestChatConfig", {
      id: this.$store.getters.chatId
    })
  }

}
