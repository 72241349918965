























































































































































































































































import VuSendMessageExtra from '@/components/vu-components/VuSendMessageExtra.vue'
import ChatBackups from '@/components/ChatBackups.vue'
import ExportConfig from '../../components/chat/ExportConfigs.vue'
import ImportConfig from '../../components/chat/ImportConfigs.vue'
import TransferLicense from '../../components/chat/TransferLicense.vue'
import RemoveForPeriod from './removeForPeriod/RemoveForPeriod.vue'
import ChatActions from '@/components/chat/ChatActions.vue'
import AddAdmin from '@/components/AddAdmin/AddAdmin.vue'
import { InputSetups } from '@/mixins/input-setups'
import { AddAdminType } from '@/includes/types/AddAdmin/Enums'

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins, Ref } from 'vue-property-decorator'

@Component({
  components: {
    AddAdmin,
    'vu-send-message-extra': VuSendMessageExtra,
    ChatBackups,
    ExportConfig,
    ImportConfig,
    TransferLicense,
    RemoveForPeriod,
    Icon,
    PageTitle,
    ChatActions,
    ConfigField,
    HighlightAnchor
  },
  data() {
    return {
      AddAdminType
    }
  }
})
export default class Extra extends Mixins<InputSetups>(InputSetups) {
  @Ref('export-chat-configs') exportConfig!: ExportConfig

  isImportModalOpen = false

  exportSettings = false

  isTransferLicenseModalOpen = false

  exportSettingsHandler(): void {
    if (this.$store.state.triggersState.frontendTriggers && this.$store.state.triggersState.frontendTriggers.length) {
      this.exportSettings = true
    } else {
      this.exportConfig.export_chat_config = true
      this.exportConfig.exportSettings()
    }
  }

  mounted(): void {
    this.$baseTemplate.saveButton.hide()

    if (this.$store.getters.isChatSet && !this.$store.state.triggersState.frontendTriggers) {
      this.$store.dispatch('updateTriggers')
    }
  }
}
